<div class="content">
  <div class="content__image">
    <img src="/images/charityBackg.jpg" alt="">
    <div class="content__image__text">
      <h1>The Keg Spirit Foundation</h1>
      <p>The Keg Spirit Foundation continues The Keg’s history of working with communities to support children and youth across different charities and programs.</p>
    </div>
  </div>

  <div class="content__qr">
    <div class="description">PLEASE SCAN QR<br>TO DONATE</div>
    <img class="qr_img" src="/images/charityQr.png" alt="">
  </div>
</div>
