import { Component } from '@angular/core';
import { NgIf } from '@angular/common';
import { QrViewComponent } from '@app/components/qr-view/qr-view.component';
import { ActivitySectionDirective, UserActivityClickTrackingDirective } from '@core';

@Component({
  selector: 'app-store-tab',
  standalone: true,
  templateUrl: './store-tab.component.html',
  imports: [
    NgIf,
    QrViewComponent,
    ActivitySectionDirective,
    UserActivityClickTrackingDirective,
  ],
  styleUrls: ['./store-tab.component.scss'],
})
export class StoreTabComponent {

  isQRVisible = false;
  title = '';
  description = '';
  qrSrc = '';
  imgSrc = '';

  showQRCode(title: string, description: string, imgSrc: string, qrSrc: string): void {
    this.isQRVisible = true;
    this.title = title;
    this.imgSrc = imgSrc;
    this.qrSrc = qrSrc;
    this.description = description;
  }

}
